import React, { useEffect, useState } from "react";
import { Icons } from "styles/variables";
import { Icon } from "@iconify/react";
import Logo from "assets/svg/logo_full_white.svg";
// import LogoWhite from "assets/svg/logo_full_white.svg";
import useScrollDirection from "../../hooks/scrollDirection";

import { Nav, NavItem, ContactBtn, NavItems, Container } from "./styles";

const Navbar: React.FC<{ page?: string }> = ({ page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stickNavbar, setStickNavbar] = useState(false);
  const scrollDirection = useScrollDirection();
  const [aboutHref, setAboutHref] = useState("#about");
  const [letsTalkHref, setLetsTalkHref] = useState("#talk");

  useEffect(() => {
    if (page === "terms" || page === "privacy" || page === "sign-in") {
      setAboutHref("/#about");
      setLetsTalkHref("/#talk");
    }
  }, [aboutHref, letsTalkHref, page]);

  useEffect(() => {
    if (scrollDirection && scrollDirection === "up" && window.scrollY !== 0) {
      setStickNavbar(true);
    } else {
      setStickNavbar(false);
    }
  }, [scrollDirection]);

  return (
    <Container page={`${page}`} scrolled={stickNavbar}>
      <Nav isOpen={isOpen} page={`${page}`} scrolled={stickNavbar}>
        <div className="nav-header">
          {/* eslint-disable */}
          <span
            className="logo-box"
            role="button"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img
              className="nav-logo"
              src={
                isOpen || page === "sign-in" || page === "terms" ? Logo : Logo
              }
              alt="Logo"
            />
          </span>
        </div>

        {/* <NavItems className="items">
          <NavItem
            page={`${page}`}
            scrolled={stickNavbar}
            className="nav-item"
            onClick={() => setIsOpen(false)}
            href={page === "home" ? "#" : "/"}
          >
            Home
          </NavItem>
          <NavItem
            page={`${page}`}
            scrolled={stickNavbar}
            className="nav-item"
            onClick={() => setIsOpen(false)}
            href={`${aboutHref}`}
          >
            About
          </NavItem>
          <ContactBtn
            page={`${page}`}
            scrolled={stickNavbar}
            onClick={() => setIsOpen(false)}
            href={`${letsTalkHref}`}
          >
            Let&apos;s talk
          </ContactBtn>
        </NavItems> */}
      </Nav>
    </Container>
  );
};

export default Navbar;
