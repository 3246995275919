/* eslint-disable consistent-return */
import styled, { css } from "styled-components";
import { Breakpoints, Colors, Fonts, FontSizes } from "styles/variables";
import { navProps } from "libs/navProps";

export const Container = styled.div<navProps>`
  transition: all 0.3s ease;
  position: relative;
  min-height: 80px;
  height: fit-content;

  @media screen and (min-width: ${Breakpoints.ipad}) {
    min-height: ${(props) => (props.page === "home" ? "0" : "80px")};
  }
`;

export const Nav = styled.div<navProps>`
  transition: 0.3s all ease !important;
    .logo-box {
        cursor: pointer;
    }
  ${({ scrolled }) =>
    scrolled && `position: fixed !important; z-index: 100 !important;`}
  @media screen and (min-width: ${Breakpoints.ipad}) {
    width: 100%;
    z-index: 2;
    background: ${(props) => {
      if (props.page === "about" && props.scrolled) return Colors.cnbarStrong;
      if (props.page === "home" && props.scrolled) return Colors.cnbar;
      if (
        (props.page === "terms" ||
          props.page === "privacy" ||
          props.page === "sign-in") &&
        props.scrolled
      )
        return "#ffffff";
    }};
    position: absolute;
    top: 0;
    left: 0;
    padding: ${({ scrolled }) =>
      scrolled ? "24px 0 24px 146px;" : "24px 0 24px 146px;"}
    display: flex;
    align-items: center;

    .nav-logo {
      min-height: 29px;
      margin-right: 54px;
    }

    .nav-header {
      display: flex;
    }
  }

  @media screen and (max-width: ${Breakpoints.ipad}) {
    width: 100%;
    display: flex;
    z-index: 2;
    background: ${(props) => {
      if (props.page === "about") return Colors.cnbarStrong;
      if (props.page === "home") return Colors.cnbar;
      if (props.page === "sign-in") return "#ffffff";
    }};
    min-height: 80px;
    max-height: 80px;
    position: relative;
    padding: 11px 20px;
    flex-direction: column;
    padding-top: 22px;
    /* transition: 0.4s all ease-in-out; */

    .nav-logo {
      min-height: 22px;
    }

    .nav-header {
      width: 100%;
      display: flex;
      position: relative;
      height: 100%;
      /* padding-top: 8px; */

      .menu-bars {
        display: flex;
        position: absolute;
        right: 0;

        .icon {
          color: ${(props) =>
            props.page === "sign-in" || props.page === "terms"
              ? Colors.cnbar
              : "#ffffff"};
        }
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.mobile}) and (max-width: ${
  Breakpoints.ipad
}) {
    min-height: 80px;
    max-height: 80px;

    .nav-logo {
      min-height: 44px;
    }

    .nav-header {
      .menu-bars {
        .icon {
          height: 40px;
          width: 40px;
        }
      }
    }

    ${(props) =>
      props.isOpen &&
      css`
        background: ${Colors.cnbarLight};
        max-height: 360px;

        .nav-header .menu-bars {
          .icon {
            color: #000000;
          }
        }
      `}
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    position: absolute;
    z-index: 20;
    padding-top: 32px;

    ${(props) =>
      props.isOpen &&
      css`
        background: ${Colors.cnbarLight};
        max-height: 360px;

        .nav-header .menu-bars {
          .icon {
            color: #000000;
          }
        }
      `}
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    .menu-bars {
      display: none;
    }
  }
`;

export const NavItems = styled.div`
  @media screen and (min-width: ${Breakpoints.ipad}) {
    font-family: ${Fonts.poppins};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${Breakpoints.ipad}) {
    flex-direction: column;
    transition: 0.4s;
    overflow: hidden;
    transition: 0.3s;
    font-family: ${Fonts.poppins};
    margin-top: 26px;
    width: 100%;
    align-items: flex-start;

    .nav-item {
      display: block;
    }
  }
`;

export const NavItem = styled.a<navProps>`
  font-size: ${FontSizes.xmd};
  font-weight: 300;

  @media screen and (min-width: ${Breakpoints.desktop}) {
    border-bottom: none;
    margin-bottom: 0;
    width: fit-content;
    color: ${({ scrolled }) =>
      scrolled ? `${Colors.white}` : `${Colors.scndBlack}`};
    font-size: ${FontSizes.xmd};
    display: inline-block;
    position: relative;
    height: fit-content;
    margin: 15px 4px;

    :after {
      content: "";
      height: 5px;
      bottom: 0;
      left: 0;
      background-color: ${Colors.cnbar};
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.25s ease-out;
    }

    :hover {
      :after {
        transform: scaleX(1);
        transform-origin: center;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    font-weight: 600;
    color: ${({ scrolled }) =>
      scrolled ? `${Colors.white}` : `${Colors.scndBlack}`};
    margin-right: 60px;
  }

  @media screen and (max-width: ${Breakpoints.ipad}) {
    :first-child {
      margin-bottom: 13px;
    }
  }
`;

export const ContactBtn = styled.a<navProps>`
  @media screen and (min-width: ${Breakpoints.desktop}) {
    border-bottom: none;
    margin-bottom: 0;
    width: fit-content;
    color: ${({ scrolled }) =>
      scrolled ? `${Colors.white}` : `${Colors.scndBlack}`};
    font-size: ${FontSizes.xmd};
    display: inline-block;
    position: relative;
    height: fit-content;
    margin: 15px 4px;

    :after {
      content: "";
      height: 5px;
      bottom: 0;
      left: 0;
      background-color: ${Colors.cnbar};
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.25s ease-out;
    }

    :hover {
      :after {
        transform: scaleX(1);
        transform-origin: center;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    font-weight: 600;
    color: ${({ scrolled }) =>
      scrolled ? `${Colors.white}` : `${Colors.scndBlack}`};
    font-size: ${FontSizes.xmd};
  }

  @media screen and (max-width: ${Breakpoints.ipad}) {
    background: ${Colors.cnbarMed};
    font-size: ${FontSizes.xmd};
    color: ${Colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    margin-top: 13px;
    width: 234px;
    height: 52px;
    font-weight: 600;
  }
`;
