import styled from "styled-components";
import { Breakpoints, Colors, Fonts, FontSizes } from "styles/variables";
import MobilePhoto from "assets/img/landing_page_mobile1.png";
import DesktopPhoto from "assets/img/landing_page_desktop.jpg";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 80px 0 100px 20px;
  background: url(${MobilePhoto});
  background-size: cover;

  .phrase {
    font-weight: 800;
    font-size: ${FontSizes.xlg};
    font-family: ${Fonts.poppins};
    color: #ffffff;
    line-height: 59.4px;
    z-index: 2;
  }

  @media screen and (min-width: ${Breakpoints.desktop}) {
    min-height: 100vh;
    max-height: fit-content;
    height: unset;
  }

  @media screen and (min-width: ${Breakpoints.mobile}) {
    background: url(${DesktopPhoto}) no-repeat;

    .phrase {
      width: 75%;
    }
  }

  @media screen and (min-width: ${Breakpoints.sm_mobile}) {
    background-size: cover;

    .phrase {
      font-weight: 700;
      line-height: 73px;
    }
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    justify-content: center;
    padding: 160px 0 0 160px;
    background-position: center;

    .phrase {
      font-size: ${FontSizes.xxlg};
      margin: 0 0 61px 0;
    }
  }

  @media screen and (min-width: ${Breakpoints.mobile}) and (max-width: ${Breakpoints.ipad}) {
    background-position: center;

    .phrase {
      font-size: ${FontSizes.xxlg};
    }
  }

  @media screen and (max-width: ${Breakpoints.sm_mobile}) {
    .phrase {
      font-size: ${FontSizes.lg};
      width: 90%;
    }
  }
`;

export const SignInContainer = styled.div`
  @media screen and (max-width: ${Breakpoints.desktop}) {
  }

  @media screen and (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 64px;
  }

  z-index: 2;
  position: relative;
  width: 283px;
  background: #fff;
  /* height: 166px; */
  border-radius: 8px;
  padding: 29px 27px 24px 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #000000;
    font-size: ${FontSizes.rg};
    font-weight: 400;
    font-family: ${Fonts.inter};
    margin-bottom: 16px;
    line-height: 26px;
  }

  .signUp-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: ${Colors.cnbar};
    font-size: ${FontSizes.xmd};
    font-family: ${Fonts.poppins};
    border-radius: 24px;
    width: 204px;
    height: 52px;
    font-weight: 600;
    transition: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: ${Breakpoints.sm_mobile}) {
    width: 80%;
    padding: 17px 15px;

    .signUp-btn {
      width: 100%;
    }
  }
`;

export const Presentation = styled.div`
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .intro,
  .description {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description {
    background: rgba(254, 235, 221, 1);
    width: 100%;
    line-height: 42px;
    font-size: ${FontSizes.lg};
    color: ${Colors.cnbar};
    font-weight: 700;
    padding: 0 20px;
    font-family: ${Fonts.poppins};
  }

  .intro {
    background: rgb(251, 98, 85);
    padding: 80px 0;

    .logo_reduced {
      min-width: 42px;
      max-width: 42px;
      min-height: 42px;
      max-height: 42px;
      margin-left: 20px;
    }

    .title {
      font-weight: 700;
      font-family: ${Fonts.poppins};
      font-size: ${FontSizes.xxlg};
      color: #ffffff;
      line-height: 73px;
      margin: 15px 0 0 20px;
      width: 81%;
    }

    .mock-image {
      max-width: 70%;
      margin-top: 90px;
    }
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    .intro,
    .description {
      flex-direction: row;
    }

    .intro {
      justify-content: center;
      align-items: center;

      .intro-title {
        width: 50%;

        .logo_reduced {
          min-width: 125px;
          max-width: 125px;
          min-height: 125px;
          max-height: 125px;
          margin-left: 0;
        }

        .title {
          font-size: ${FontSizes.xxlg};
          margin: 28px 0 0 0;
          font-weight: 700;
          line-height: 73px;
        }
      }

      .mock-image {
        max-width: 30%;
        object-fit: cover;
      }
    }

    .description {
      font-size: ${FontSizes.xlg};
      padding: 0 160px;
      line-height: 80px;
    }
  }

  @media screen and (min-width: ${Breakpoints.mobile}) and (max-width: ${Breakpoints.ipad}) {
    .intro {
      .logo_reduced {
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
      }

      .title {
        font-size: ${FontSizes.xlg};
      }

      .description {
        font-size: ${FontSizes.med};
      }
    }
  }
`;

export const ThirdSection = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 134px 65px 89px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.ipad}) {
    padding: 200px 162px;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 89px;

  :last-child {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 14px;
    font-family: ${Fonts.poppins};
    font-weight: 700;
    font-size: ${FontSizes.lg};
    color: ${Colors.scndBlack};
  }

  .description {
    font-size: ${FontSizes.rg};
    line-height: 26px;
    color: ${Colors.black};
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    .title {
      font-size: ${FontSizes.xxlg};
      margin-bottom: 32px;
    }

    .description {
      font-size: ${FontSizes.xmd};
      line-height: 40px;
    }
  }

  @media screen and (min-width: ${Breakpoints.mobile}) and (max-width: ${Breakpoints.ipad}) {
    .title {
      font-size: ${FontSizes.xlg};
    }

    .description {
      font-size: ${FontSizes.med};
    }
  }
`;
