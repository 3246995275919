import React, { useState, useEffect, lazy, Suspense } from "react";
import Navbar from "components/Navbar";
import ReducedLogo from "assets/svg/logo_lines.svg";
import LogoLinesReduced from "assets/svg/logo_lines_reduced.svg";
import MockUp from "assets/svg/mascot_toast.svg";
import Loading from "components/Loading";

import {
  Container,
  Intro,
  SignInContainer,
  Presentation,
  ThirdSection,
  InfoBox,
} from "./styles";

const LetsTalk = lazy(() => import("components/LetsTalk"));
const About = lazy(() => import("components/About"));
const Footer = lazy(() => import("components/Footer"));

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    const body = document.querySelector("body");
    if (!body) return;
    body.style.overflowY = "hidden";
    setTimeout(() => {
      body.style.overflowY = "scroll";
      setLoading(false);
    }, 200);
  }, [loading]);

  // const words = [
  //   "outing",
  //   "brunch meetup",
  //   "hockey game",
  //   "wild night",
  //   "dinner date",
  // ];

  // const [index, setIndex] = useState(0);
  // const [subIndex, setSubIndex] = useState(0);
  // const [blink, setBlink] = useState(true);
  // const [reverse, setReverse] = useState(false);

  // // // blinker
  // useEffect(() => {
  //   const timeout2 = setTimeout(() => {
  //     setBlink((prev) => !prev);
  //   }, 500);
  //   return () => clearTimeout(timeout2);
  // }, [blink]);

  // // typewriter
  // useEffect(() => {
  //   if (index === words.length) return setIndex(0);

  //   if (subIndex === words[index].length + 1 && !reverse) {
  //     setReverse(true);
  //     return undefined;
  //   }

  //   if (subIndex === 0 && reverse) {
  //     setReverse(false);
  //     setIndex((prev) => prev + 1);
  //     return undefined;
  //   }

  //   const timeout = setTimeout(() => {
  //     setSubIndex((prev) => prev + (reverse ? -1 : 1));
  //   }, Math.max(reverse ? 75 : subIndex === words[index].length ? 500 : 50, parseInt(`${Math.random() * 200}`, 10)));

  //   return () => clearTimeout(timeout);
  // }, [subIndex, index, reverse, words]);

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Navbar page="home" />

        <Intro>
          <div className="phrase">
            {/* <p>
              The app that
              {window.outerWidth > 768 ? <br /> : " "}
              makes every
              <br />
              {`${words[index] ? words[index].substring(0, subIndex) : ""}${
                blink ? "|" : ""
              }`}
              <br />
              matter.
            </p> */}
            <p>
              Your plans
              <br />
              made easy
            </p>
          </div>

          <SignInContainer>
            <p className="title">
              Instantly decide where to go out with your friends
            </p>
            <a className="signUp-btn" href="https://try.minimatch.app">
              Start Now
            </a>
          </SignInContainer>
        </Intro>

        <Presentation>
          <div className="intro">
            <div className="intro-title">
              <img
                className="logo_reduced"
                src={window.outerWidth > 768 ? LogoLinesReduced : ReducedLogo}
                alt="Reduced logo"
              />
              <p className="title">
                Match helps your friend group make it happen
              </p>
            </div>
            <img className="mock-image" src={MockUp} alt="mock-up" />
          </div>
          <div
            style={{
              flexDirection: "column",
              paddingTop: "60px",
              paddingBottom: "60px",
              backgroundColor: "#fff",
            }}
            className="description"
          >
            <div style={{ alignSelf: "self-start" }}>
              Answer a few simple questions and Match will:
            </div>
            <br />
            <ul
              style={{
                listStylePosition: "initial",
                paddingLeft: "30px",
                paddingRight: "20px",
              }}
            >
              <li>
                Find places tailored to your group’s collective rather than
                individual interests
              </li>
              <br />
              <li>
                Generate a shortlist of reccomendations your entire friend group
                will enjoy
              </li>
              <br />
              <li>
                Save you time and effort when searching for and deciding on your
                plans
              </li>
            </ul>
          </div>
        </Presentation>

        {/* <ThirdSection>
          <div>
            <InfoBox>
              <p className="title">Discover</p>
              <p className="description">
                There&apos;s so much more out there. Minimap will help you find
                places and events you didn&apos;t even know your city had to
                offer. Let us suggest experiences from your friends&apos;
                approved recommendations and our supercharged recommendations
                engine.
              </p>
            </InfoBox>

            <InfoBox>
              <p className="title">Share</p>
              <p className="description">
                Check out your friends&apos; maps to see their favorite places
                and events. Rank places and create bar-crawls for your friends,
                then share them with the world.
              </p>
            </InfoBox>

            <InfoBox>
              <p className="title">Organize</p>
              <p className="description">
                Want to test the waters? Send out a feeler to all or some of
                your friends. Poll them for night-out preferences. Update your
                locations.
              </p>
            </InfoBox>
          </div>
        </ThirdSection> */}

        <Suspense fallback={<Loading />}>
          <About id="about" />
        </Suspense>

        {/* <Suspense fallback={<Loading />}>
          <LetsTalk id="talk" />
        </Suspense> */}

        <Suspense fallback={<Loading />}>
          <Footer />
        </Suspense>
      </Container>
    </>
  );
};

export default Home;
